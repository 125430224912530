import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const ContentItem = ({item}) => {
  return (
    <div className={`content-item`}>
      <p className='content-item__type'>{item.content_type.type}</p>
      <CustomLink className='content-item__link' link={{url: `/${item.content_type.slug}/${item.slug}`, newTab: false}}>
        {item.title}
      </CustomLink>
    </div>
  )
}
export default ContentItem
