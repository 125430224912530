import React from "react"
import CustomLink from '../custom-link'
import ContentItem from './content-item'
import Image from "../image";

const ContentList = ({content}) => {
  return (
    <div className={`content-list container`}>
    {content.map((item, i) => {
      return (
        <ContentItem key={'content'+i} item={item}/>
      )
    })}
    <div className={`content-item`}></div>
    </div>
  )
}
export default ContentList
