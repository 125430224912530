import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const HeroSmall = ({data}) => {
  return (
    <div className={`hero-small`}>
      <div className='hero-small__container container'>
        <div className='hero-small__content'>
          {data.label && (
            <h5 className='hero-small__label'>{data.label}</h5>
          )}

          {data.header && (
            <h1 className='hero-small__header'>{data.header}</h1>
          )}

          {data.copy && (
            <p className='hero-small__copy'>{data.copy}</p>
          )}

          {data.cta && (
              <CustomLink className={`button-${data.cta.type} hero-small__cta`} key={data.cta.id} link={data.cta}>{data.cta.text}</CustomLink>
          )}
        </div>

        {data.image && (
          <div className="hero-small__image-container">
            <div className="hero-small__image-overlay"/>
            <Image media={data.image} className="hero-small__image"/>
          </div>
        )}
      </div>
    </div>
  )
}
export default HeroSmall
