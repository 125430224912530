import React from "react"
import Layout from "../components/layout"
import CustomLink from '../components/custom-link'
import ContentList from '../components/content/content-list'
import HeroSmall from '../components/sections/hero-small'

import "../styles/app.scss"
import { graphql } from "gatsby"

const Content = ({ data }) => {

  return (
    <>
      <Layout>
        <HeroSmall data={{ header: 'Content Hub', label: null, copy: null, image: null, cta: null}}/>
        <ContentList content={data.strapi.contents}/>
      </Layout>
    </>
  )
}

export default Content


export const query = graphql`
  query ContentPageQuery {
    strapi {
      contents {
        content
        content_type {
          type
          id
          slug
        }
        author
        date
        id
        slug
        title
        image {
          alternativeText
          height
          url
        }
      }
    }
  }
`